define("discourse/plugins/discourse-presence/discourse/components/composer-presence-display", ["exports", "discourse-common/utils/decorators", "@ember/object/computed", "@ember/component", "@ember/service"], function (_exports, _decorators, _computed, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_dec = (0, _decorators.default)("model.replyingToTopic", "model.editingPost", "model.whisper", "model.composerOpened"), _dec2 = (0, _decorators.default)("model.topic.id", "isReply", "isWhisper"), _dec3 = (0, _decorators.default)("model.topic.id", "isReply", "isWhisper"), _dec4 = (0, _decorators.default)("isEdit", "model.post.id"), _dec5 = (0, _decorators.observes)("replyChannelName", "whisperChannelName", "editChannelName"), _dec6 = (0, _decorators.default)("isReply", "replyingUsers.[]", "editingUsers.[]"), _dec7 = (0, _decorators.on)("didInsertElement"), _dec8 = (0, _decorators.observes)("model.reply", "state", "model.post.id", "model.topic.id"), _dec9 = (0, _decorators.on)("willDestroyElement"), (_obj = {
    tagName: "",
    presence: (0, _service.inject)(),
    composerPresenceManager: (0, _service.inject)(),

    state(replyingToTopic, editingPost, whisper, composerOpen) {
      if (!composerOpen) {
        return;
      } else if (editingPost) {
        return "edit";
      } else if (whisper) {
        return "whisper";
      } else if (replyingToTopic) {
        return "reply";
      }
    },

    isReply: (0, _computed.equal)("state", "reply"),
    isEdit: (0, _computed.equal)("state", "edit"),
    isWhisper: (0, _computed.equal)("state", "whisper"),

    replyChannelName(topicId, isReply, isWhisper) {
      if (topicId && (isReply || isWhisper)) {
        return "/discourse-presence/reply/".concat(topicId);
      }
    },

    whisperChannelName(topicId, isReply, isWhisper) {
      if (topicId && this.currentUser.whisperer && (isReply || isWhisper)) {
        return "/discourse-presence/whisper/".concat(topicId);
      }
    },

    editChannelName(isEdit, postId) {
      if (isEdit) {
        return "/discourse-presence/edit/".concat(postId);
      }
    },

    _setupChannel(channelKey, name) {
      var _this$channelKey;

      if (((_this$channelKey = this[channelKey]) === null || _this$channelKey === void 0 ? void 0 : _this$channelKey.name) !== name) {
        var _this$channelKey2;

        (_this$channelKey2 = this[channelKey]) === null || _this$channelKey2 === void 0 ? void 0 : _this$channelKey2.unsubscribe();

        if (name) {
          this.set(channelKey, this.presence.getChannel(name));
          this[channelKey].subscribe();
        } else if (this[channelKey]) {
          this.set(channelKey, null);
        }
      }
    },

    _setupChannels() {
      this._setupChannel("replyChannel", this.replyChannelName);

      this._setupChannel("whisperChannel", this.whisperChannelName);

      this._setupChannel("editChannel", this.editChannelName);
    },

    _cleanupChannels() {
      this._setupChannel("replyChannel", null);

      this._setupChannel("whisperChannel", null);

      this._setupChannel("editChannel", null);
    },

    replyingUsers: (0, _computed.union)("replyChannel.users", "whisperChannel.users"),
    editingUsers: (0, _computed.readOnly)("editChannel.users"),

    presenceUsers(isReply, replyingUsers, editingUsers) {
      var _users$filter;

      const users = isReply ? replyingUsers : editingUsers;
      return users === null || users === void 0 ? void 0 : (_users$filter = users.filter(u => u.id !== this.currentUser.id)) === null || _users$filter === void 0 ? void 0 : _users$filter.slice(0, this.siteSettings.presence_max_users_shown);
    },

    shouldDisplay: (0, _computed.gt)("presenceUsers.length", 0),

    subscribe() {
      this._setupChannels();
    },

    _contentChanged() {
      var _this$model, _this$model2;

      if (this.model.reply === "") {
        return;
      }

      const entity = this.state === "edit" ? (_this$model = this.model) === null || _this$model === void 0 ? void 0 : _this$model.post : (_this$model2 = this.model) === null || _this$model2 === void 0 ? void 0 : _this$model2.topic;
      this.composerPresenceManager.notifyState(this.state, entity === null || entity === void 0 ? void 0 : entity.id);
    },

    closeComposer() {
      this._cleanupChannels();

      this.composerPresenceManager.leave();
    }

  }, (_applyDecoratedDescriptor(_obj, "state", [_dec], Object.getOwnPropertyDescriptor(_obj, "state"), _obj), _applyDecoratedDescriptor(_obj, "replyChannelName", [_dec2], Object.getOwnPropertyDescriptor(_obj, "replyChannelName"), _obj), _applyDecoratedDescriptor(_obj, "whisperChannelName", [_dec3], Object.getOwnPropertyDescriptor(_obj, "whisperChannelName"), _obj), _applyDecoratedDescriptor(_obj, "editChannelName", [_dec4], Object.getOwnPropertyDescriptor(_obj, "editChannelName"), _obj), _applyDecoratedDescriptor(_obj, "_setupChannels", [_dec5], Object.getOwnPropertyDescriptor(_obj, "_setupChannels"), _obj), _applyDecoratedDescriptor(_obj, "presenceUsers", [_dec6], Object.getOwnPropertyDescriptor(_obj, "presenceUsers"), _obj), _applyDecoratedDescriptor(_obj, "subscribe", [_dec7], Object.getOwnPropertyDescriptor(_obj, "subscribe"), _obj), _applyDecoratedDescriptor(_obj, "_contentChanged", [_dec8], Object.getOwnPropertyDescriptor(_obj, "_contentChanged"), _obj), _applyDecoratedDescriptor(_obj, "closeComposer", [_dec9], Object.getOwnPropertyDescriptor(_obj, "closeComposer"), _obj)), _obj)));

  _exports.default = _default;
});
define("discourse/plugins/discourse-presence/discourse/components/topic-presence-display", ["exports", "discourse-common/utils/decorators", "@ember/component", "@ember/object/computed", "@ember/service"], function (_exports, _decorators, _component, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_dec = (0, _decorators.default)("replyChannel.users.[]"), _dec2 = (0, _decorators.default)("whisperChannel.users.[]"), _dec3 = (0, _decorators.default)("topic.id"), _dec4 = (0, _decorators.default)("topic.id"), _dec5 = (0, _decorators.on)("willDestroyElement"), (_obj = {
    topic: null,
    presence: (0, _service.inject)(),
    replyChannel: null,
    whisperChannel: null,

    replyUsers(users) {
      return users === null || users === void 0 ? void 0 : users.filter(u => u.id !== this.currentUser.id);
    },

    whisperUsers(users) {
      return users === null || users === void 0 ? void 0 : users.filter(u => u.id !== this.currentUser.id);
    },

    users: (0, _computed.union)("replyUsers", "whisperUsers"),

    replyChannelName(id) {
      return "/discourse-presence/reply/".concat(id);
    },

    whisperChannelName(id) {
      return "/discourse-presence/whisper/".concat(id);
    },

    shouldDisplay: (0, _computed.gt)("users.length", 0),

    didReceiveAttrs() {
      var _this$replyChannel, _this$whisperChannel;

      this._super(...arguments);

      if (((_this$replyChannel = this.replyChannel) === null || _this$replyChannel === void 0 ? void 0 : _this$replyChannel.name) !== this.replyChannelName) {
        var _this$replyChannel2;

        (_this$replyChannel2 = this.replyChannel) === null || _this$replyChannel2 === void 0 ? void 0 : _this$replyChannel2.unsubscribe();
        this.set("replyChannel", this.presence.getChannel(this.replyChannelName));
        this.replyChannel.subscribe();
      }

      if (this.currentUser.staff && ((_this$whisperChannel = this.whisperChannel) === null || _this$whisperChannel === void 0 ? void 0 : _this$whisperChannel.name) !== this.whisperChannelName) {
        var _this$whisperChannel2;

        (_this$whisperChannel2 = this.whisperChannel) === null || _this$whisperChannel2 === void 0 ? void 0 : _this$whisperChannel2.unsubscribe();
        this.set("whisperChannel", this.presence.getChannel(this.whisperChannelName));
        this.whisperChannel.subscribe();
      }
    },

    _destroyed() {
      var _this$replyChannel3, _this$whisperChannel3;

      (_this$replyChannel3 = this.replyChannel) === null || _this$replyChannel3 === void 0 ? void 0 : _this$replyChannel3.unsubscribe();
      (_this$whisperChannel3 = this.whisperChannel) === null || _this$whisperChannel3 === void 0 ? void 0 : _this$whisperChannel3.unsubscribe();
    }

  }, (_applyDecoratedDescriptor(_obj, "replyUsers", [_dec], Object.getOwnPropertyDescriptor(_obj, "replyUsers"), _obj), _applyDecoratedDescriptor(_obj, "whisperUsers", [_dec2], Object.getOwnPropertyDescriptor(_obj, "whisperUsers"), _obj), _applyDecoratedDescriptor(_obj, "replyChannelName", [_dec3], Object.getOwnPropertyDescriptor(_obj, "replyChannelName"), _obj), _applyDecoratedDescriptor(_obj, "whisperChannelName", [_dec4], Object.getOwnPropertyDescriptor(_obj, "whisperChannelName"), _obj), _applyDecoratedDescriptor(_obj, "_destroyed", [_dec5], Object.getOwnPropertyDescriptor(_obj, "_destroyed"), _obj)), _obj)));

  _exports.default = _default;
});
define("discourse/plugins/discourse-presence/discourse/services/composer-presence-manager", ["exports", "@ember/service", "@ember/runloop", "discourse-common/config/environment"], function (_exports, _service, _runloop, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const PRESENCE_CHANNEL_PREFIX = "/discourse-presence";
  const KEEP_ALIVE_DURATION_SECONDS = 10;
  let ComposerPresenceManager = (_class = class ComposerPresenceManager extends _service.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "presence", _descriptor, this);
    }

    notifyState(intent, id) {
      if (this.siteSettings.allow_users_to_hide_profile && this.currentUser.hide_profile_and_presence) {
        return;
      }

      if (intent === undefined) {
        return this.leave();
      }

      if (!["reply", "whisper", "edit"].includes(intent)) {
        throw "Unknown intent ".concat(intent);
      }

      const state = "".concat(intent, "/").concat(id);

      if (this._state !== state) {
        this._enter(intent, id);

        this._state = state;
      }

      if (!(0, _environment.isTesting)()) {
        this._autoLeaveTimer = (0, _runloop.debounce)(this, this.leave, KEEP_ALIVE_DURATION_SECONDS * 1000);
      }
    }

    leave() {
      var _this$_presentChannel;

      (_this$_presentChannel = this._presentChannel) === null || _this$_presentChannel === void 0 ? void 0 : _this$_presentChannel.leave();
      this._presentChannel = null;
      this._state = null;

      if (this._autoLeaveTimer) {
        (0, _runloop.cancel)(this._autoLeaveTimer);
        this._autoLeaveTimer = null;
      }
    }

    _enter(intent, id) {
      this.leave();
      let channelName = "".concat(PRESENCE_CHANNEL_PREFIX, "/").concat(intent, "/").concat(id);
      this._presentChannel = this.presence.getChannel(channelName);

      this._presentChannel.enter();
    }

    willDestroy() {
      this.leave();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "presence", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = ComposerPresenceManager;
});
Ember.TEMPLATES["javascripts/components/composer-presence-display"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[30,0,[\"shouldDisplay\"]],[[[1,\"  \"],[10,0],[14,0,\"presence-users\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"presence-avatars\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"presenceUsers\"]]],null]],null],null,[[[1,\"        \"],[1,[28,[35,3],[[30,1]],[[\"imageSize\"],[\"small\"]]]],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,1],[14,0,\"presence-text\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"description\"],[12],[1,\"\\n\"],[41,[30,0,[\"isReply\"]],[[[1,[28,[35,4],[\"presence.replying\"],[[\"count\"],[[30,0,[\"presenceUsers\",\"length\"]]]]]]],[]],[[[1,[28,[35,4],[\"presence.editing\"],[[\"count\"],[[30,0,[\"presenceUsers\",\"length\"]]]]]]],[]]],[1,\"\"],[13],[1,\"\"],[10,1],[14,0,\"wave\"],[12],[1,\"\"],[10,1],[14,0,\"dot\"],[12],[1,\".\"],[13],[10,1],[14,0,\"dot\"],[12],[1,\".\"],[13],[10,1],[14,0,\"dot\"],[12],[1,\".\"],[13],[1,\"\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"user\"],false,[\"if\",\"each\",\"-track-array\",\"avatar\",\"i18n\"]]","moduleName":"javascripts/discourse/templates/components/composer-presence-display","isStrictMode":false});
Ember.TEMPLATES["javascripts/components/topic-presence-display"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[30,0,[\"shouldDisplay\"]],[[[1,\"  \"],[10,0],[14,0,\"presence-users\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"presence-avatars\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"users\"]]],null]],null],null,[[[1,\"        \"],[8,[39,3],null,[[\"@user\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,4],[[30,1]],[[\"imageSize\"],[\"small\"]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,1],[14,0,\"presence-text\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"description\"],[12],[1,[28,[35,5],[\"presence.replying_to_topic\"],[[\"count\"],[[30,0,[\"users\",\"length\"]]]]]],[13],[10,1],[14,0,\"wave\"],[12],[10,1],[14,0,\"dot\"],[12],[1,\".\"],[13],[10,1],[14,0,\"dot\"],[12],[1,\".\"],[13],[10,1],[14,0,\"dot\"],[12],[1,\".\"],[13],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"user\"],false,[\"if\",\"each\",\"-track-array\",\"user-link\",\"avatar\",\"i18n\"]]","moduleName":"javascripts/discourse/templates/components/topic-presence-display","isStrictMode":false});
Ember.TEMPLATES["javascripts/connectors/before-composer-controls/presence"] = Ember.HTMLBars.template({"id":null,"block":"[[[8,[39,0],null,[[\"@model\"],[[30,0,[\"model\"]]]],null],[1,\"\\n\"]],[],false,[\"composer-presence-display\"]]","moduleName":"javascripts/discourse/templates/connectors/before-composer-controls/presence","isStrictMode":false});
Ember.TEMPLATES["javascripts/connectors/topic-above-footer-buttons/presence"] = Ember.HTMLBars.template({"id":null,"block":"[[[8,[39,0],null,[[\"@topic\"],[[30,0,[\"model\"]]]],null],[1,\"\\n\"]],[],false,[\"topic-presence-display\"]]","moduleName":"javascripts/discourse/templates/connectors/topic-above-footer-buttons/presence","isStrictMode":false});

